import { Container, Button } from "react-bootstrap";
import React, { useState } from "react";
import { useAuth } from "../../Auth/AuthProvider";
import PostCard from "../post/PostCard";
import PostDetail from "../post/postDatail/PostDetail";
import {
    useDeleteHistoryMutation,
    useDeleteSuspendedTournamentMutation,
    useUnlikeHistoryMutation,
} from "../../api/cardContainerAPI";
import useTournamentHub from "../tournament/useTournamentHub";
import "./css/CardContainer.css";

const CardContainer = ({ containerName, list, type }) => {
    const { token, username } = useAuth();
    const [showDetail, setShowDetail] = useState(false);
    const [detailTournamentInfo, setDetailTournamentInfo] = useState(null);

    const createButtonType = { suspended: "이어서 하기", history: "다시 해보기", like: "다시 해보기" };
    const deleteButtonType = { suspended: "삭제", history: "삭제", like: "좋아요 취소" };

    const tournamentHub = useTournamentHub();
    const mutationDeleteSuspendedTournament = useDeleteSuspendedTournamentMutation(token, username);
    const mutationDeleteHistory = useDeleteHistoryMutation(token, username);
    const mutationUnlikeHistory = useUnlikeHistoryMutation(token, username);

    const recreateTournament = (tournament) => {
        const createdTournament = {
            numberOfItem: tournament.itemList.length,
            itemList: tournament.itemList,
            survivedItemIndexList: [...Array(tournament.itemList.length).keys()],
            nextItem: [0, 1],
            roundInfo: { mainRound: tournament.itemList.length, subRound: 1 },
            selectedItemIndexList: [],
            isCreated: false,
        };
        tournamentHub({
            tournament: createdTournament,
            isHost: true,
            chatMode: true,
        });
    };

    const continueTournament = (tournament) => {
        const createdTournament = {
            numberOfItem: tournament.itemList.length,
            itemList: tournament.itemList,
            survivedItemIndexList: tournament.survivedItemIndexList,
            nextItem: tournament.nextItem,
            roundInfo: tournament.roundInfo,
            selectedItemIndexList: tournament.selectedItemsIndex,
            isCreated: false,
        };
        tournamentHub({
            tournament: createdTournament,
            isHost: true,
            chatMode: true,
        });
    };

    const handleShowDetail = (index) => {
        setDetailTournamentInfo(list[index]);
        setShowDetail(true);
    };

    const handleDeleteClick = (tournament) => {
        if (type === "suspended") {
            mutationDeleteSuspendedTournament.mutate({ tournament });
        } else if (type === "history") {
            mutationDeleteHistory.mutate({ tournament });
        } else {
            mutationUnlikeHistory.mutate({ tournament });
        }
    };

    const handleCreateClick = ({ tournament }) => {
        if (type === "suspended") {
            mutationDeleteSuspendedTournament.mutate({ tournament });
            continueTournament(tournament);
        } else {
            recreateTournament(tournament);
        }
    };

    return (
        <div>
            <Container className="twitter-container">
                <h2 className="twitter-section-title">{containerName}</h2>
                {list.length ? (
                    <div className="twitter-feed">
                        {list.map((tournament, index) => (
                            <div key={index} className="twitter-card">
                                <PostCard itemList={tournament.itemList} />
                                <p className="twitter-card-date">
                                    {new Date(tournament.createdAt)
                                    .toLocaleDateString()} {new Date(tournament.createdAt)
                                        .toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' })}
                                    </p>
                                <div className="twitter-card-actions">
                                    <Button
                                        variant="outline-danger"
                                        size="sm"
                                        onClick={() => handleDeleteClick(tournament)}
                                    >
                                        {deleteButtonType[type]}
                                    </Button>
                                    <Button
                                        variant="outline-primary"
                                        size="sm"
                                        onClick={() => handleCreateClick({ tournament })}
                                    >
                                        {createButtonType[type]}
                                    </Button>
                                    <Button
                                        variant="info"
                                        size="sm"
                                        onClick={() => handleShowDetail(index)}
                                    >
                                        자세히
                                    </Button>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <p className="no-data">경기가 없습니다.</p>
                )}
            </Container>

            {/* Detail Modal */}
            {showDetail && (
                <PostDetail
                    showModal={showDetail}
                    tournament={detailTournamentInfo}
                    onClose={() => setShowDetail(false)}
                    type={type}
                    handleDeleteClick={handleDeleteClick}
                    handleCreateClick={handleCreateClick}
                />
            )}
        </div>
    );
};

export default CardContainer;
