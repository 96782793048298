import checkResponse from "./checkResponse/checkResponse";
import apiBaseUrl from "./config/apiBaseUrl";

export const saveHistory = async ({token, itemList, selectedItemIndex, username, isCreated}) => {
    const response = await fetch(`${apiBaseUrl}/history/save`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            'itemList': itemList,
            'selectedItemsIndex': selectedItemIndex,
            'createdBy': username,
            'isCreated': isCreated,
        })
    })
    return checkResponse(response, saveHistory.name)
}

export const deleteHistory = async ({token, historyId, username, isCreated}) => {
    const response = await fetch(`${apiBaseUrl}/history/delete`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            'historyId': historyId,
            'username': username,
            'isCreated': isCreated,
        })
    })
    return checkResponse(response, saveHistory.name)
}

export const fetchAllHistory = async ({queryKey}) => {
    const [,currentPage, pageSize, username] = queryKey;
    const response = await fetch(`${apiBaseUrl}/history`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "page": currentPage,
            "size": pageSize,
            "sortBy": "postedAt",
            "sortOrder": "desc",
            "username": username,
        }),
    });
    return checkResponse(response, fetchAllHistory.name);
}

export const fetchUserHistory = async ({queryKey}) => {
    const [,token, currentPage, pageSize, username] = queryKey;
    const response = await fetch(`${apiBaseUrl}/history/user`,{
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            "page": currentPage,
            "size": pageSize,
            "sortBy": "postedAt",
            "sortOrder": "desc",
            "username": username,
        }),
    });
    return checkResponse(response, fetchUserHistory.name);
}

export const fetchUserLikedHistory = async ({queryKey}) => {
    const [,token, currentPage, pageSize, username] = queryKey;
    const response = await fetch(`${apiBaseUrl}/history/user/like`,{
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            "page": currentPage,
            "size": pageSize,
            "sortBy": "likedAt",
            "sortOrder": "desc",
            "username": username,
        }),
    });
    return checkResponse(response, fetchUserLikedHistory.name);
}

