import { Modal } from "react-bootstrap";
import TournamentDiagram from "./TournamentDiagram";
import React from "react";
import "../css/PostDetail.css"

const PostDetail = ({
                        showModal,
                        tournament,
                        onClose,
                        handleCreateClick,
                        type,
                        navigate,
                    }) => {
    const createButtonType = {
        suspended: "이어서 하기",
        history: "다시 해보기",
        like: "경기 생성",
    };

    return (
        <Modal
            show={showModal}
            onHide={onClose}
            centered
            dialogClassName="dynamic-size-modal"
        >
            <Modal.Header className="modal-header">
                <Modal.Title>경기 기록</Modal.Title>
                <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    onClick={onClose}
                >
                </button>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <TournamentDiagram tournament={tournament} />
            </Modal.Body>
            <Modal.Footer className="modal-footer">
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => handleCreateClick({ tournament, navigate })}
                >
                    {createButtonType[type]}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default PostDetail;
