import { useRef, useState, } from "react";
import MousePointerPosition from "./MousePointerPosition";
import "bootstrap/dist/css/bootstrap.min.css";
import NextItemBox from "./NextItemBox";

const TournamentViewBox = ({ roomId, stompClient, tournamentState, isHost, handleSelectItem, handleImageError }) => {
    const [mousePointerPosition, setMousePointerPosition] = useState(null);
    const boxRef = useRef(null);

    return (
        <div className="container-fluid vh-100 d-flex align-items-center justify-content-center" ref={boxRef}>
            <div className="card border-0 shadow-sm p-3 w-100" style={{ borderRadius: '15px', maxWidth: '1200px' }}>
                <div className="text-center mb-4">
                    <h3 className="fw-bold stage-info mb-0">
                        {tournamentState?.roundInfo.mainRound > 2
                            ? `${tournamentState.roundInfo.mainRound}강 / ${tournamentState.roundInfo.subRound}라운드`
                            : "결승"}
                    </h3><br/>
                </div>

                <div className="row">
                    {tournamentState ? (
                        <NextItemBox
                            isHost={isHost}
                            tournamentState={tournamentState}
                            handleImageError={handleImageError}
                            handleSelectItem={handleSelectItem}
                        />
                    ) : (
                        <div className="col text-center">
                            <p>Loading...</p>
                        </div>
                    )}
                    <small className="text-muted">* 마우스 포인터 위치가 실시간으로 참가자들에게 공유됩니다.</small>
                    <small className="text-muted">* 이미지가 나오지 않을 경우 자동으로 리로드합니다. 혹시나 리로드 되지 않을 경우 새로고침을 눌러주세요.</small>
                    <small className="text-muted">* 랜덤한 이미지 검색 결과를 가져오기 때문에 다소 이상한 이미지가 있을 수 있습니다 😅.</small>
                </div>
            </div>

            {/* Mouse Pointer Position Indicator */}
            {mousePointerPosition && !isHost && (
                <div
                    style={{
                        position: "absolute",
                        top: `${mousePointerPosition.y}px`,
                        left: `${mousePointerPosition.x}px`,
                        width: "10px",
                        height: "10px",
                        backgroundColor: "red",
                        borderRadius: "50%",
                        transform: "translate(-50%, -50%)",
                        pointerEvents: "none",
                    }}
                />
            )}

            <MousePointerPosition
                roomId={roomId}
                stompClient={stompClient}
                isHost={isHost}
                boxRef={boxRef}
                setMousePointerPosition={setMousePointerPosition}
            />
        </div>
    );
};

export default TournamentViewBox;
