import React, {useEffect, useState} from 'react';
import {useRef} from "react";
import apiBaseUrl from "../../api/config/apiBaseUrl";

const SignUpModal = ({ onClose, onSignupSuccess, onSignupFailure }) => {
    const [signupUsername, setSignupUsername] = useState('');
    const [signupPassword, setSignupPassword] = useState('');
    const usernameFocus = useRef();

    const handleSignup = async (e) => {
        e.preventDefault();
        const response = await fetch(`${apiBaseUrl}/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "username": signupUsername,
                "password": signupPassword,
                "role": '맛초보',
            }),
        });

        const data = await response.json();

        if (data.status === "success") {
            onSignupSuccess('가입이 완료되었습니다!');
        } else {
            if (data.message === 'Username is already taken') {
                onSignupFailure('이미 존재하는 아이디입니다');
            } else {
                onSignupFailure('유효하지 않은 입력입니다');
            }

        }
    };

    useEffect(() => {
        usernameFocus.current.focus();
    },[])

    return (
        <div
            className="modal show d-block"
            tabIndex="-1"
            role="dialog"
            style={{
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "100vh",
            }}
        >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">회원 가입</h5>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={onClose}
                            aria-label="Close"
                        >{}</button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleSignup}>
                            <div className="form-group">
                                <label htmlFor="signupUsername">아이디</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="signupUsername"
                                    value={signupUsername}
                                    onChange={(e) => setSignupUsername(e.target.value)}
                                    placeholder="새로운 아이디를 입력하세요"
                                    ref={usernameFocus}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="signupPassword">비밀번호</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="signupPassword"
                                    value={signupPassword}
                                    onChange={(e) => setSignupPassword(e.target.value)}
                                    placeholder="비밀번호를 입력하세요"
                                />
                            </div>
                            <button type="submit" className="btn btn-primary btn-block mt-3">
                                가입하기
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignUpModal;
