import React from "react";

const UnauthenticatedToast = ({ message, type, onClose }) => {
    return (
        <div
            className="toast show position-fixed top-0 end-0 m-3"
            style={{
                zIndex: 1055,
                padding: '0.5rem 1rem',
                border: `1px solid ${type === 'success' ? '#198754' : '#dc3545'}`, // Dynamic border based on type
                backgroundColor: type === 'success' ? '#d4edda' : '#f8d7da', // Background color based on type
                color: type === 'success' ? '#155724' : '#721c24', // Text color based on type
                borderRadius: '0.375rem', // Slightly rounded corners
                display: 'inline-flex', // Inline-flex for content width adjustment
                maxWidth: '80vw', // Prevent toast from being too wide
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Subtle shadow for better visibility
            }}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexGrow: 1,
                }}
            >
                <strong style={{ marginRight: '1rem' }}>Notification</strong>
                <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {message}
        </span>
            </div>
            <button
                type="button"
                style={{
                    marginLeft: '1rem',
                    backgroundColor: 'transparent',
                    border: 'none',
                    color: 'inherit',
                    fontSize: '1.25rem',
                    cursor: 'pointer',
                }}
                onClick={onClose}
            >
                &times;
            </button>
        </div>
    );
};

export default UnauthenticatedToast;