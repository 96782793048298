import Tournament from "./Tournament";
import ChatMessage from "./ChatMessage";
import { useLocation } from "react-router-dom";
import {
    useFetchHistoryMutation,
    useTerminateChatMutation,
    useUpdateChatRoomInfoMutation,
} from "../../api/chatAPI";
import { useState, useEffect, useRef, useCallback } from "react";
import { Client } from "@stomp/stompjs";
import { useAuth } from "../../Auth/AuthProvider";
import "../../css/TournamentUI.css";
import { createDefaultChatRoomInfo, createDefaultTournamentState } from "./tournamentLogic";
import ChatRoomDetail from "../home/ChatRoomDetail";
import { useQueryClient } from "@tanstack/react-query";
import apiBaseUrl from "../../api/config/apiBaseUrl";

const modifyToWebsocketUrl = (url) => url.replace(/^https?:\/\//, '');

const TournamentUI = () => {

    const WEBSOCKET_URL = `wss://${modifyToWebsocketUrl(apiBaseUrl)}/chat`;
    const { username } = useAuth();
    const location = useLocation();
    const isHost = useRef(null);
    const chatMode = useRef(null);
    const roomId = useRef('');
    const [createdTournament, setCreatedTournament] = useState(null);
    const [chatHistory, setChatHistory] = useState(null);
    const [showDetail, setShowDetail] = useState(false);
    const socket = useRef(null);
    const stompClient = useRef(null);

    useEffect(() => {
        if (location.state.tournament) {
            sessionStorage.setItem('locationState', JSON.stringify(location.state));

            const savedState = JSON.parse(sessionStorage.getItem('locationState'));
            setCreatedTournament(savedState.tournament || {});
            roomId.current = savedState.roomId || '0';
            chatMode.current = savedState.chatMode || false;
            isHost.current = savedState.isHost || false;
        }
    }, [location.state]);

    const fetchChatHistoryMutation = useFetchHistoryMutation(roomId.current);

    useEffect(() => {
        if (createdTournament) {
            socket.current = new WebSocket(WEBSOCKET_URL);
            stompClient.current = new Client({
                webSocketFactory: () => socket.current,
                connectHeaders: {
                    roomId: roomId.current,
                    username: username,
                    isHost: isHost.current,
                    chatRoomInfo: isHost.current
                        ? JSON.stringify(createDefaultChatRoomInfo(roomId.current, username, createdTournament, chatMode.current))
                        : "",
                    tournamentState: isHost.current
                        ? JSON.stringify(createDefaultTournamentState(createdTournament))
                        : "",
                },
                onConnect: fetchChatHistory,
            });

            stompClient.current.activate();

            return () => {
                stompClient.current.deactivate();
            };
        }
    }, [createdTournament, roomId.current, isHost.current]);

    const fetchChatHistory = async () => {
        try {
            const data = await fetchChatHistoryMutation.mutateAsync();
            setChatHistory(data);
        } catch (error) {
            console.error('Error fetching chat history:', error);
        }
    };

    return (
        <div
            className="tournament-ui d-flex"
            style={{
                height: "100vh",
                overflow: "hidden",
                marginTop: "-56px",
            }}
        >
            {/* Tournament */}
            <div
                className="left-panel"
                style={{
                    flex: "0 0 65%",
                    borderRight: "1px solid #ddd",
                    padding: "20px",
                }}
            >
                {chatHistory?.tournamentState && (
                    <Tournament
                        roomId={roomId.current}
                        createdTournament={createdTournament}
                        initialTournamentState={chatHistory.tournamentState}
                        isHost={isHost.current}
                        stompClient={stompClient.current}
                        style={{ height: "100%", width: "100%" }}
                    />
                )}
            </div>

            {/* Chatting */}
            <div
                className="right-panel"
                style={{
                    flex: "0 0 35%",
                    padding: "20px",
                }}
            >
                {chatHistory?.chatMessageList && (
                    <ChatMessage
                        roomId={roomId.current}
                        chatMessageList={chatHistory.chatMessageList}
                        stompClient={stompClient.current}
                        isHost={isHost.current}
                        chatMode={chatMode.current}
                    />
                )}
            </div>

            {/* Show detail modal */}
            {showDetail && (
                <ChatRoomDetail
                    showModal={showDetail}
                    tournament={chatHistory?.tournamentState}
                    onClose={() => setShowDetail(false)}
                />
            )}
        </div>
    );
};

export default TournamentUI;
