export const createNextTournament = (tournament, selectedItemIndex, unselectedItemIndex) => {
    // Update survivedItemIndexList
    const survivedItemIndexList = tournament.survivedItemIndexList.filter(
        index => (index !== selectedItemIndex && index !== unselectedItemIndex)
    );
    survivedItemIndexList.push(selectedItemIndex);

    // Update selectedItemsIndex
    const selectedItemIndexList = [...tournament.selectedItemIndexList, selectedItemIndex];

    // Advance the round logic
    let roundInfo;
    if (survivedItemIndexList.length === tournament.roundInfo.mainRound / 2) {
        roundInfo = {
            mainRound: tournament.roundInfo.mainRound / 2,
            subRound: 1,
        }
    } else {
        roundInfo = {
            mainRound: tournament.roundInfo.mainRound,
            subRound: tournament.roundInfo.subRound + 1,
        }
    }

    const nextItem = [survivedItemIndexList[0], survivedItemIndexList[1]];

    return {
        numberOfItem: tournament.numberOfItem,
        itemList: tournament.itemList,
        survivedItemIndexList: survivedItemIndexList,
        nextItem: nextItem,
        roundInfo: roundInfo,
        selectedItemIndexList: selectedItemIndexList,
        isCreated: tournament.isCreated,
    };
};

export const checkTournamentEnd = (tournament) => {
    return tournament.selectedItemIndexList.length === tournament.numberOfItem - 1;
};

export const sortIndex = (tournament, itemIndex) => {
    const selectedItemIndex = itemIndex;
    const unselectedItemIndex = itemIndex === tournament.nextItem[0] ? tournament.nextItem[1] : tournament.nextItem[0];
    return [selectedItemIndex, unselectedItemIndex];
};

export const createSaveHistoryMutationDTO = (token, username, tournament) => {
     return {
         token: token,
         itemList: tournament.itemList,
         selectedItemIndex: tournament.selectedItemIndexList,
         username: username,
         isCreated: tournament.isCreated
     };
};

export const convertToState = (tournament) => {
    return {
        itemList: tournament.itemList,
        nextItem: tournament.nextItem,
        roundInfo: tournament.roundInfo,
        selectedItemsIndex: tournament.selectedItemIndexList,
    };
};

export const selectItemMutationDTO = (token, username, tournament, selectedItemIndex, unselectedItemIndex) => {
    return {
        token: token,
        username: username,
        itemList: tournament.itemList,
        selectedItemIndex: selectedItemIndex,
        unselectedItemIndex: unselectedItemIndex,
        isChampion: tournament.roundInfo.mainRound === 2,
    };
};

export const createDefaultTournamentState = (tournament) => {
    return {
        itemList: tournament.itemList,
        nextItem: tournament.nextItem,
        roundInfo: tournament.roundInfo,
        selectedItemsIndex: tournament.selectedItemIndex,
    };
}

export const createDefaultChatRoomInfo = (roomId, username, tournament, chatMode) => {
    return {
        roomId: roomId,
        hostName: username,
        joinedUserList: [],
        itemList: tournament.itemList,
        createdAt: new Date().toLocaleTimeString(),
        chatMode: chatMode,
    };
};

