import {Toast, ToastContainer} from "react-bootstrap";
import {useNavigate} from "react-router-dom";

const TokenExpirationToast = ({ showToast, setShowToast }) => {
    const navigate = useNavigate();

    const handleToastClose = () => {
        setShowToast(false);
        navigate('/login');
    };

    return (
        <ToastContainer position="top-end" className="p-3">
            <Toast onClose={handleToastClose} show={showToast} delay={3000} autohide>
                <Toast.Header>
                    <strong className="me-auto">Session Expired</strong>
                </Toast.Header>
                <Toast.Body>Your session has expired. Please log in again.</Toast.Body>
            </Toast>
        </ToastContainer>
    );
}

export default TokenExpirationToast;