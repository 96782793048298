import NormalDistributionChart from "./NormalDistributionChart";
import "../css/UserScoreCard.css";
import React from "react";

const UserScoreCard = ({userInfo, countUserHistory}) => {
    const mean = 50;
    const stdDev = 15;
    const userScore = 65;
    const score = Math.round(countUserHistory ? userInfo.score / countUserHistory : 0).toFixed(2);
    return (
        <div className="statistics-container">
            <h2>(점수 통계는 유저 수 100명 이상부터 적용됩니다.)</h2>
            <div className="chart-container">
                <NormalDistributionChart mean={mean} stdDev={stdDev} userScore={userScore} />
            </div>
            <span>선호도 일치 지수는 다음과 같습니다.</span>
            <span>(1 - 선택한 음식의 평균 선호확률) + (미선택한 음식의 평균 선호확률)</span>
        </div>
    );
};

export default UserScoreCard;