import React from "react";
import "../css/UserPreferenceCard.css";

const UserPreferenceCard = ({ preference }) => {
    const { byWin, byRatio, byChampion, byLose } = (
        preference.byWin ? preference : {
            byWin: {win: 0},
            byRatio: {ratio: 0},
            byChampion: {champion: 0},
            byLose: {lose: 0},
        }
    );

    const preferenceData = [
        { title: "가장 많이 고른 음식", data: byWin, valueKey: "win", valueLabel: "선택 횟수" },
        { title: "가장 높은 확률로 고른 음식", data: byRatio, valueKey: "ratio", valueLabel: "%", multiplier: 100 },
        { title: "가장 많이 우승한 음식", data: byChampion, valueKey: "champion", valueLabel: "우승 횟수" },
        { title: "가장 많이 외면한 음식", data: byLose, valueKey: "lose", valueLabel: "미선택 횟수" },
    ];

    return (
        <div className="preference-card-container-horizontal">
            {preferenceData.map(({ title, data, valueKey, valueLabel, multiplier }, index) => (
                <div className="preference-card" key={index}>
                    <h3 className="preference-title">{title}</h3>
                    <img src={data.imageUrl} alt={data.name} className="preference-image" />
                    <p className="preference-name">{data.name}</p>
                    <p className="preference-value">
                        {multiplier ? (data[valueKey] * multiplier).toFixed(0) : data[valueKey]} {valueLabel}
                    </p>
                </div>
            ))}
        </div>
    );
};

export default UserPreferenceCard;
