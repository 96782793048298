import { useState, useEffect } from "react";
import { useAuth } from "../../Auth/AuthProvider";
import { fetchSingleItem, sendSelectItemInfo } from "../../api/tournamentAPI";
import {useMutation} from "@tanstack/react-query";
import { saveHistory } from "../../api/historyAPI";
import {
    checkTournamentEnd,
    convertToState,
    createNextTournament,
    createSaveHistoryMutationDTO,
    selectItemMutationDTO,
    sortIndex,
} from "./tournamentLogic";
import TournamentViewBox from "./TournamentViewBox";
import TournamentViewBoxChampion from "./TournamentViewBoxChampion";
import {fetchItemRecord} from "../../api/recordAPI";
import apiBaseUrl from "../../api/config/apiBaseUrl";

const Tournament = ({ roomId, createdTournament, initialTournamentState, isHost, stompClient }) => {
    const { token, username, role } = useAuth();

    const [tournament, setTournament] = useState(() => {
        const sessionData = JSON.parse(sessionStorage.getItem("tournament"));
        return sessionData ? sessionData : createdTournament;
    });

    const [tournamentState, setTournamentState] = useState(initialTournamentState);

    const [isChampion, setIsChampion] = useState(() => {
            const sessionData = JSON.parse(sessionStorage.getItem("end"));
            return sessionData ? sessionData : false;
    });

    const [championItemInfo, setChampionItemInfo] = useState(() => {
        const sessionData = JSON.parse(sessionStorage.getItem("end"));
        return sessionData ? sessionData : null;
    });

    const selectItemMutation = useMutation({
        mutationFn: sendSelectItemInfo,
    });

    const saveHistoryMutation = useMutation({
        mutationFn: saveHistory,
    });

    const fetchItemInfoMutation = useMutation({
        mutationFn: (itemName) => fetchItemRecord({queryKey:['fetchItemInfoQuery', token, itemName]}),
    })

    const fetchSingleItemMutation = useMutation({
        mutationFn: ({ index, name }) => fetchSingleItem(token, roomId, index, name),
        onSuccess: (data, variables) => {
            const { index } = variables;
            const updatedItem = { ...tournament.itemList[index], url: data.url };
            const updatedItemList = [...tournament.itemList];
            updatedItemList[index] = updatedItem;
            const updatedTournament = { ...tournament, itemList: updatedItemList };

            setTournament(updatedTournament);
        },
    });

    const handleSelectItem = (itemIndex) => {
        const [selectedItemIndex, unselectedItemIndex] = sortIndex(tournament, itemIndex);

        // Save selection record at the server
        selectItemMutation.mutate(selectItemMutationDTO(token, username, tournament, selectedItemIndex, unselectedItemIndex));

        // Create new tournament
        const newTournament = createNextTournament(tournament, selectedItemIndex, unselectedItemIndex);
        setTournament(newTournament);

        // If ends
        if (checkTournamentEnd(newTournament)) {
            // Save history at the server
            const saveHistoryDTO = createSaveHistoryMutationDTO(token, username, newTournament)
            saveHistoryMutation.mutate(saveHistoryDTO);

            // Fetch champion item information
            const championItemIndex = newTournament.survivedItemIndexList[0];
            const itemName = newTournament.itemList[championItemIndex].name;
            fetchItemInfoMutation.mutate(itemName,{
                onSuccess: (data) => {
                    setChampionItemInfo(data);
                    sessionStorage.setItem("end", JSON.stringify(data));
                }
            })
        }

        stompClient.publish({
            destination: `/app/chat/${roomId}/tournament`,
            body: JSON.stringify(convertToState(newTournament)),
        });
    };

    // Websocket subscription to tournamentState
    useEffect(() => {
        if (!stompClient || !stompClient.connected) {
            return;
        }

        const subscription = stompClient.subscribe(`/topic/tournament/${roomId}`, (message) => {
            try {
                const tournamentState = JSON.parse(message.body);
                setTournamentState(tournamentState);
            } catch (error) {
                console.error("Error parsing tournament state:", error);
            }
        });

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [roomId, stompClient]);

    useEffect(() => {
        if (tournamentState && tournamentState.selectedItemsIndex.length === tournamentState.itemList.length - 1) {
            setIsChampion(true);
        }
    }, [tournamentState]);

    // Save tournament process to sessionStorage
    useEffect(() => {
        if (isHost) {
            sessionStorage.setItem("tournament", JSON.stringify(tournament));
            sessionStorage.setItem("roomId", JSON.stringify(roomId));
        }
    }, [tournament]);

    // Re-fetch image source
    const handleImageError = (index) => {
        if (isHost) {
            console.log('새로 이미지를 가져옵니다 index: ',index )
            fetchSingleItemMutation.mutate({ index, name: tournament.itemList[index].name });
        }
    };

    return (
        <div style={{ height: "100%", width: "100%"}}>
            {!isChampion ? (
                <TournamentViewBox
                    roomId={roomId}
                    stompClient={stompClient}
                    tournamentState={tournamentState}
                    isHost={isHost}
                    handleSelectItem={handleSelectItem}
                    handleImageError={handleImageError}
                />
            ) : (
                isChampion && championItemInfo && <TournamentViewBoxChampion
                    item={
                        tournamentState.itemList[
                            tournamentState.selectedItemsIndex[tournamentState.selectedItemsIndex.length - 1]
                            ]
                    }

                    championItemInfo = {championItemInfo}
                />
            )}
        </div>
    );
};

export default Tournament;