import React, { useState } from "react";
import { useAuth } from "../../Auth/AuthProvider";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toggleLike } from "../../api/likeAPI";
import UnauthenticatedToast from "../login/UnauthenticatedToast";
import PostDetail from "./postDatail/PostDetail";
import PostCard from "./PostCard";
import { useNavigate } from "react-router-dom";
import useTournamentHub from "../tournament/useTournamentHub";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

const PostContainer = ({ historyList, setHistoryList }) => {
    const navigate = useNavigate();
    const { token, username } = useAuth();
    const [showDetail, setShowDetail] = useState(false);
    const [detailTournamentInfo, setDetailTournamentInfo] = useState(null);
    const [showToast, setShowToast] = useState(false);
    const queryClient = useQueryClient();
    const tournamentHub = useTournamentHub();

    const mutation = useMutation({
        mutationFn: ({ tournamentId }) => toggleLike(token, username, tournamentId),
        onSettled: () => queryClient.invalidateQueries({ queryKey: ['fetchAllHistoryQuery'] }),
    });

    const recreateTournament = (tournament) => {
        const createdTournament = {
            numberOfItem: tournament.itemList.length,
            itemList: tournament.itemList,
            survivedItemIndexList: [...Array(tournament.itemList.length).keys()],
            nextItem: [0, 1],
            roundInfo: { mainRound: tournament.itemList.length, subRound: 1 },
            selectedItemIndexList: [],
            isCreated: false,
        };
        tournamentHub({
            tournament: createdTournament,
            isHost: true,
            chatMode: true,
        });
    };

    const handleLikeClick = (index, postId) => {
        if (username.trim() !== '') {
            setHistoryList((prevList) => {
                const newList = [...prevList];
                newList[index] = {
                    ...newList[index],
                    hasUserLiked: !newList[index].hasUserLiked,
                    likeCount: newList[index].hasUserLiked
                        ? newList[index].likeCount - 1
                        : newList[index].likeCount + 1,
                };
                return newList;
            });
            mutation.mutate({ tournamentId: postId });
        } else {
            setShowToast(true);
        }
    };

    const handleCreateClick = ({ tournament }) => {
        recreateTournament(tournament, navigate);
    };

    const handleShowDetail = (index) => {
        setDetailTournamentInfo(historyList[index]);
        setShowDetail(true);
    };

    return (
        <div className="container py-5">
            <div className="row">
                {historyList?.map((post, index) => (
                    <div key={index} className="col-12 mb-4">
                        <div className="d-flex align-items-start p-3 border rounded shadow-sm">
                            <div className="me-3">
                                <PostCard itemList={post.itemList} />
                            </div>
                            <div className="flex-grow-1">
                                <div className="d-flex justify-content-between align-items-center mb-2">
                                    <h5 className="mb-0 text-truncate" title={post.name}>{post.name}</h5>
                                    <small className="text-muted">
                                        {
                                            new Date(post.createdAt)
                                            .toLocaleDateString()} {new Date(post.createdAt)
                                            .toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' })
                                        }</small>
                                </div>
                                <p className="text-muted mb-3">{`${post.itemList.length}개의 음식이 참가했습니다.`}</p>
                                <div className="d-flex align-items-center">
                                    <button
                                        className={`btn btn-sm ${post.hasUserLiked ? 'btn-primary' : 'btn-outline-secondary'} me-2`}
                                        onClick={() => handleLikeClick(index, post.id)}
                                    >
                                        <i className={`${post.hasUserLiked ? 'fas' : 'far'} fa-thumbs-up`}></i> {post.likeCount}
                                    </button>
                                    <button
                                        className="btn btn-outline-primary btn-sm"
                                        onClick={() => handleShowDetail(index)}
                                    >
                                        자세히
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {showDetail && (
                <PostDetail
                    showModal={showDetail}
                    tournament={detailTournamentInfo}
                    onClose={() => setShowDetail(false)}
                    type={'like'}
                    handleCreateClick={handleCreateClick}
                    navigate={navigate}
                />
            )}

            {showToast && (
                <UnauthenticatedToast
                    message={'You need to log in to perform this action.'}
                    type={'warning'}
                    onClose={() => setShowToast(false)}
                />
            )}
        </div>
    );
};

export default PostContainer;
