import React from 'react';
import {Modal} from 'react-bootstrap';

const RoleDescriptionModal = ({showModal, onClose, roleTextColor, roleDescription}) => {
    const roleDetail = {
        '맛잘알': ['선호도 일치 지수가 약 상위 25% 이상', '표준 정규분포 상에서 z = +0.7 이상'],
        '맛초보': ['선호도 일치 지수가 약 상위 75% ~ 25%', '표준 정규분포 상에서 z = -0.7 ~ +0.7'],
        '맛알못': ['선호도 일치 지수가 약 상위 75% 이하', '표준 정규분포 상에서 z = -0.7 이하'],
    }

    return (
        <Modal show={showModal} onHide={onClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>등급</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {['맛잘알','맛초보','맛알못'].map((role, index)=>
                <div className="role-tweet" style={tweetStyle}>
                    <div className="role-header" style={{color: roleTextColor[role], fontWeight: 'bold'}}>
                        {role}
                    </div>
                    <div className="role-body">
                        {roleDescription[role]}
                    </div>
                    <div className="role-footer" style={footerStyle}>
                        <span>{roleDetail[role][0]}</span> | <span>{roleDetail[role][1]}</span>
                    </div>
                </div>
            )}
            </Modal.Body>
            <Modal.Footer style={{...footerStyle, fontSize: '0.7em'}}>
                <span >선호도 일치 지수는 다음과 같습니다.</span>
                <span >(1 - 선택한 음식의 평균 선호확률) + (미선택한 음식의 평균 선호확률)</span>
            </Modal.Footer>
        </Modal>
    );
};

// Inline styles for Twitter-style design
const tweetStyle = {
    border: '1px solid #e1e8ed',
    borderRadius: '10px',
    padding: '10px',
    marginBottom: '10px',
    backgroundColor: '#ffffff',
    boxShadow: '0 1px 3px rgba(0,0,0,0.1)'
};

const footerStyle = {
    fontSize: '0.8em',
    color: '#657786',
    marginTop: '8px'
};

export default RoleDescriptionModal;
