import {useState, useEffect} from "react";
import devHistory from "./dev_history.txt";

const DevHistory = () => {
    const [content, setContent] = useState("");

    useEffect(() => {
        fetch(devHistory)
            .then((response) => response.text())
            .then((text) => setContent(text))
            .catch((error) => console.error("Error loading file:", error));
    }, []);

    const makeUrlsClickable = (text) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g; // Matches http/https URLs
        return text.split("\n").map((line, index) => (
            <p
                key={index}
                style={{fontSize: '16px'}}
            >
                {line.split(urlRegex).map((part, idx) =>
                    urlRegex.test(part) ? (
                        <a
                            key={idx}
                            href={part}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "blue", textDecoration: "underline" }}
                        >
                            {part}
                        </a>
                    ) : (
                        part+'\n'
                    )
                )}
            </p>
        ));
    };

    return (
        <div className="container"
             style={{marginTop: '150px'}}
        >
            <h2 className="text-center mb-4">개발 노트</h2>
            <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>{makeUrlsClickable(content)}</pre>
        </div>
    );
};

export default DevHistory;
