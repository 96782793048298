import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {saveSuspendedTournament} from "../api/tournamentAPI";
import {useAuth} from "../Auth/AuthProvider";
import {useMutation} from "@tanstack/react-query";
import {terminateChat} from "../api/chatAPI";

const ClearSessionStorage = () => {
    const location = useLocation();
    const {token, username} = useAuth();

    const terminateMutation = useMutation({
        mutationFn: (roomId) => terminateChat({roomId}),
    })

    useEffect(() => {
        if (!location.pathname.startsWith("/tournament")) {
            const tournamentData = JSON.parse(sessionStorage.getItem('tournament'));
            const roomId = JSON.parse(sessionStorage.getItem('roomId'));

            if (!!tournamentData && (tournamentData.selectedItemIndexList.length < (tournamentData.itemList.length - 1))) {
                    saveSuspendedTournament({
                        token,
                        username,
                        itemList: tournamentData.itemList,
                        survivedItemIndexList: tournamentData.survivedItemIndexList,
                        nextItem: tournamentData.nextItem,
                        roundInfo: tournamentData.roundInfo,
                        selectedItemIndexList: tournamentData.selectedItemIndexList,
                    })
            }
            if (!!roomId) {
                terminateMutation.mutate(roomId);
            }

            sessionStorage.clear();
        }

    }, [location]);

    return null;
};

export default ClearSessionStorage;
