import useSWR from "swr";
import {useState} from "react";

const FilterBar = ({keyword, setKeyword, sortBy, setSortBy, sortOrder, setSortOrder}) => {
    const [keywordInput, setKeywordInput] = useState();

    return (
        <div className="row mb-3">
            {/* Searching bar */}
            <div className="col-md-4">
                <input
                    type="text"
                    className="form-control"
                    placeholder="검색어를 입력하세요."
                    value={keywordInput}
                    onChange={(e) => setKeywordInput(e.target.value)}
                />
            </div>
            <div className="col-md-2">
                <button
                    className="btn btn-primary"
                    onClick={() => setKeyword(keywordInput)}
                >
                    검색
                </button>
                <button
                    className="btn btn-primary"
                    onClick={() => {
                        setKeywordInput("")
                        setKeyword("")
                    }}
                >
                    초기화
                </button>
            </div>

            {/* Sort Buttons */}
            <div className="row mb-3">
                <div className="col-md-6">
                    <div className="btn-group" role="group">
                        <button
                            className={`btn btn-outline-secondary ${sortBy === 'totalWins' ? 'active' : ''}`}
                            onClick={() => setSortBy('totalWins')}
                        >
                            승
                        </button>
                        <button
                            className={`btn btn-outline-secondary ${sortBy === 'totalChampions' ? 'active' : ''}`}
                            onClick={() => setSortBy('totalChampions')}
                        >
                            우승
                        </button>
                        <button
                            className={`btn btn-outline-secondary ${sortBy === 'winLossRatio' ? 'active' : ''}`}
                            onClick={() => setSortBy('winLossRatio')}
                        >
                            승률
                        </button>
                    </div>
                    <button
                        className="btn btn-outline-secondary ms-2"
                        onClick={() => setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')}
                    >
                        {sortOrder === 'asc' ? '오름차순 ' : '내림차순 '}
                        <i className={`bi ${sortOrder === 'asc' ? 'bi-arrow-up' : 'bi-arrow-down'}`}></i>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default FilterBar;