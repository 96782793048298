import React from 'react';
import '../css/TournamentDiagram.css'
import {Table} from "react-bootstrap";
import ItemCard from "./ItemCard";

const TOURNAMENT_TEMPLATE = {
    '2': [
        [[-1,-1]],
        [-1]
    ],
    '4': [
        [[-1,-1],[-1,-1]],
        [[-1,-1]],
        [-1]
    ],
    '8': [
        [[-1,-1],[-1,-1],[-1,-1],[-1,-1]],
        [[-1,-1],[-1,-1]],
        [[-1,-1]],
        [-1]
    ],
    '16': [
        [[-1,-1],[-1,-1],[-1,-1],[-1,-1],[-1,-1],[-1,-1],[-1,-1],[-1,-1]],
        [[-1,-1],[-1,-1],[-1,-1],[-1,-1]],
        [[-1,-1],[-1,-1]],
        [[-1,-1]],
        [-1]
    ],
}

const TournamentDiagram = ({ tournament }) => {
    const recreateTournament = (tournament) => {
        const numberOfItem = tournament.itemList.length;
        let idx = 0;
        const arr = [...Array(numberOfItem).keys(), ...tournament.selectedItemsIndex];
        const recursivelyReplaceZero = (list) =>
            list.map((item) => (item === -1 ? (idx < arr.length ? arr[idx++] : -1) : recursivelyReplaceZero(item)));
        return recursivelyReplaceZero(TOURNAMENT_TEMPLATE[numberOfItem]);
    };

    const recreatedTournament = recreateTournament(tournament);

    const renderRound = (roundItems, roundIndex) => {
        return (
            <td key={roundIndex}>
                <p>{getRoundTitle(roundIndex, tournament.itemList.length)}</p>
                <div className="round-container">
                    {roundItems.map((itemPair, pairIndex) => renderItem(itemPair, roundIndex, pairIndex))}
                </div>
            </td>
        );
    };

    const renderItem = (itemPair, roundIndex, pairIndex) => {
        if (Number.isInteger(itemPair)) {
            // Single item
            return (
                <div key={`${roundIndex}-${pairIndex}-single`} className="single-item-container">
                    <ItemCard tournament={tournament} index={itemPair} isSelected={true}/>
                </div>
            );
        }
        // Pair of items
        return (
            <div key={`${roundIndex}-${pairIndex}-pair`} className="pair-container">
                <ItemCard tournament={tournament} index={itemPair[0]} isSelected={false}/>
                <div className="connector"></div>
                <ItemCard tournament={tournament} index={itemPair[1]} isSelected={false}/>
            </div>
        );
    };

    const getRoundTitle = (roundIndex, totalItems) => {
        const mainRound = totalItems * Math.pow(2, -1 * roundIndex);
        return mainRound === 1 ? "우승" : mainRound;
    };

    return (
        <Table>
            <tbody>
            <tr>
                {recreatedTournament.map((roundItems, roundIndex) => renderRound(roundItems, roundIndex))}
            </tr>
            </tbody>
        </Table>
    );
};

export default TournamentDiagram;