import { useEffect, useState } from "react";
import {throttle} from "lodash";

const MousePointerPosition = ({ roomId, stompClient, isHost, boxRef, setMousePointerPosition }) => {
    useEffect(() => {
        // Keep track of the host's mouse pointer
        if (isHost && boxRef.current && stompClient) {
            const throttledPublish = throttle((x, y) => {
                stompClient.publish({
                    destination: `/app/chat/${roomId}/mouse`,
                    body: JSON.stringify({
                        x: Math.round(x),
                        y: Math.round(y),
                    }),
                });
            }, 10);

            const handleMouseMove = (event) => {
                const rect = boxRef.current.getBoundingClientRect();
                const x = event.clientX - rect.left + boxRef.current.scrollLeft;
                const y = event.clientY - rect.top + boxRef.current.scrollTop;

                // Ensure the pointer is within bounds
                if (x >= 0 && y >= 0 && x <= rect.width && y <= rect.height) {
                    setMousePointerPosition({ x, y });
                    throttledPublish(x, y);
                }
            };

            boxRef.current.addEventListener("mousemove", handleMouseMove);

            return () => {
                try {
                    boxRef.current.removeEventListener("mousemove", handleMouseMove);
                } catch (error) {
                }

            };
        }

        if (!isHost && boxRef.current && stompClient) {
            const subscription = stompClient.subscribe(`/topic/mouse/${roomId}`, (data) => {
                const parsedData = JSON.parse(data.body);
                setMousePointerPosition({
                    x: parsedData.x,
                    y: parsedData.y,
                });
            });

            return () => {
                if (subscription) {
                    subscription.unsubscribe();
                }
            };
        }
    }, [roomId, stompClient, isHost, boxRef]);

    return false;
};

export default MousePointerPosition;
