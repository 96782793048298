import {useEffect, useState} from "react";

const NextItemBox = ({isHost, tournamentState, handleImageError, handleSelectItem}) => {
    const [isLoadingCheck, setIsLoadingCheck] = useState([false, false]);
    const [isLoadingFinished, setIsLoadingFinished] = useState(false);

    const handleLoading= (index) => {
        setIsLoadingCheck((prev)=>{
            const result = [...prev];
            result[index] = true;
            return result;
        })
    }

    useEffect(()=>{
        setIsLoadingFinished(false);
    },[tournamentState])

    useEffect(()=>{
        if (isLoadingCheck.every((v)=>v===true)){
            setIsLoadingFinished(true);
        }
    },[isLoadingCheck]);

    return (
        tournamentState.nextItem.map((itemIndex, index) => (
            <div key={index} className="col-6 d-flex justify-content-center mb-4">
                <div
                    className={`card clickable-card ${isHost ? '' : 'disabled-card'}`}
                    style={{
                        width: '100%',
                        maxWidth: '600px',
                        cursor: isHost ? 'pointer' : 'not-allowed',
                        borderRadius: '10px',
                    }}
                    onClick={() => isHost && handleSelectItem(itemIndex)}
                >
                    {/* Spinner */}
                    <div className={`${isLoadingFinished ? 'd-none' : ''}`}
                         style={{
                             display: 'flex',
                             height: '500px',
                             width: '100%',
                             objectFit: 'cover',
                             borderRadius: '10px 10px 0 0',
                             justifyContent: 'center',
                             alignItems: 'center',
                         }}>
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    <img
                        referrerPolicy="no-referrer"
                        src={tournamentState.itemList[itemIndex]?.url}
                        alt={tournamentState.itemList[itemIndex]?.name}
                        className={`card-img-top ${isLoadingFinished ? '':'d-none'}`}
                        style={{
                            height: '500px',
                            width: '100%',
                            objectFit: 'cover',
                            borderRadius: '10px 10px 0 0',
                        }}
                        onError={() => handleImageError(itemIndex)}
                        onLoad={() => handleLoading(index)}
                    />
                    <div className="card-body text-center">
                        <h5 className="card-title mb-0">
                            {tournamentState.itemList[itemIndex]?.name}
                        </h5>
                    </div>
                </div>
            </div>
        ))
    );
}

export default NextItemBox;