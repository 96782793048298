import React from "react";
import "../../css/TournamentViewBoxChampion.css";

const TournamentViewBoxChampion = ({ item, championItemInfo }) => {
    return (
        <div className="tournament-view-box">
            {/* Title */}
            <h4 className="title">오늘의 메뉴</h4>

            {/* Image Section */}
            <div className="image-container">
                <img
                    src={item.url}
                    alt={item.name}
                    className="item-image"
                />
            </div>

            {/* Name */}
            <h4 className="item-name">{item.name}</h4>

            {/* Stats Table */}
            <table className="stats-table">
                <tbody>
                <tr>
                    <td className="stat-label">승</td>
                    <td className="stat-value">{championItemInfo.win}</td>
                </tr>
                <tr>
                    <td className="stat-label">패</td>
                    <td className="stat-value">{championItemInfo.lose}</td>
                </tr>
                <tr>
                    <td className="stat-label">우승</td>
                    <td className="stat-value">{championItemInfo.champion}</td>
                </tr>
                <tr>
                    <td className="stat-label">승률</td>
                    <td className="stat-value">{(championItemInfo.ratio*100).toFixed(0)}%</td>
                </tr>
                </tbody>
            </table>
        </div>
    );
};

export default TournamentViewBoxChampion;
