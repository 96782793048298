import { Link } from "react-router-dom";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./css/SideNavbar.css";

const SideNavbar = () => {
    return (
        <div className="twitter-sidenav">
            <nav>
                <ul className="sidenav-list">
                    <li className="sidenav-item">
                        <Link className="sidenav-link" to="/user/:username/data">
                            <i className="bi bi-person-circle sidenav-icon"></i>
                            <span className="sidenav-text">내 정보</span>
                        </Link>
                    </li>
                    <li className="sidenav-item">
                        <Link className="sidenav-link" to="/user/:username/history">
                            <i className="bi bi-clock-history sidenav-icon"></i>
                            <span className="sidenav-text">경기 기록</span>
                        </Link>
                    </li>
                    <li className="sidenav-item">
                        <Link className="sidenav-link" to="/user/:username/statistics">
                            <i className="bi bi-bar-chart sidenav-icon"></i>
                            <span className="sidenav-text">내 통계</span>
                        </Link>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default SideNavbar;
