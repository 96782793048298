import React from "react";
import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
} from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, annotationPlugin);

const NormalDistributionChart = ({ mean, stdDev, userScore }) => {
    const generateNormalDistribution = (mean, stdDev) => {
        const xValues = [];
        const yValues = [];
        for (let x = mean - 4 * stdDev; x <= mean + 4 * stdDev; x += 0.1) {
            xValues.push(x.toFixed(1));
            const y = (1 / (stdDev * Math.sqrt(2 * Math.PI))) * Math.exp(-0.5 * Math.pow((x - mean) / stdDev, 2));
            yValues.push(y);
        }
        return { xValues, yValues };
    };

    const { xValues, yValues } = generateNormalDistribution(mean, stdDev);

    const data = {
        labels: xValues,
        datasets: [
            {
                data: yValues,
                borderColor: "rgba(100, 181, 246, 1)",
                borderWidth: 3,
                tension: 0.3,
                fill: true,
                backgroundColor: "rgba(100, 181, 246, 0.2)",
            },
            {
                data: xValues.map((x) => (parseFloat(x) === userScore ? Math.max(...yValues) : null)),
                borderColor: "rgba(246, 100, 181, 1)",
                borderWidth: 3,
                pointRadius: 6,
                pointBackgroundColor: "rgba(246, 100, 181, 1)",
                showLine: false,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: { display: false },
            annotation: {
                annotations: {
                    centralBar: {
                        type: "line",
                        scaleID: "x",
                        value: mean.toFixed(1),
                        borderColor: "rgba(0, 0, 0, 0.6)",
                        borderWidth: 2,
                        borderDash: [5, 5],
                    },
                },
            },
        },
        scales: {
            x: { display: true },
            y: { display: true },
        },
        elements: {
            point: {
                hoverRadius: 8,
                hoverBackgroundColor: "rgba(246, 100, 181, 0.8)",
            },
        },
        animations: {
            tension: {
                duration: 1000,
                easing: "easeInOutQuad",
            },
        },
    };

    return (
        <div style={{ position: "relative", width: "100%", height: "250px" }}>
            <Line data={data} options={options} />
        </div>
    );
};

export default NormalDistributionChart;
