import React, { useEffect, useState } from 'react';
import { Table, Spinner, Alert } from 'react-bootstrap';
import { useQuery } from '@tanstack/react-query';
import { useAuth } from '../../Auth/AuthProvider';
import { fetchAllRecord } from '../../api/recordAPI';
import StatisticsTable from "./StatisticsTable";

const Statistics = () => {
    const pageSize = 8;

    return (
        <StatisticsTable pageSize={pageSize} fetchMethod={fetchAllRecord}/>
    );
};

export default Statistics;
