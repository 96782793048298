import { Table, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "../../Auth/AuthProvider";
import "./css/StatisticsTable.css";
import FilterBar from "./FilterBar";

const StatisticsTable = ({ pageSize, fetchMethod }) => {
    const { token, username } = useAuth();
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [keyword, setKeyword] = useState("");
    const [sortBy, setSortBy] = useState("totalWins");
    const [sortOrder, setSortOrder] = useState("desc")

    const fetchRecordQuery = useQuery({
        queryKey: ['record', token, currentPage, pageSize, keyword, sortBy, sortOrder, username],
        queryFn: fetchMethod,
    });

    useEffect(() => {
        if (fetchRecordQuery.isSuccess && fetchRecordQuery.data) {
            setTotalPages(fetchRecordQuery.data.totalPages);
        }
    }, [fetchRecordQuery.isSuccess, fetchRecordQuery.data]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <div className="table-container">
            <FilterBar
                keyword={keyword} setKeyword={setKeyword}
                sortBy={sortBy} setSortBy={setSortBy}
                sortOrder={sortOrder} setSortOrder={setSortOrder}
            />
            <Table hover responsive="sm" className="table-borderless">
                <thead className="table-head">
                <tr>
                    <th className="table-header-cell">순위</th>
                    <th className="table-header-cell">이름</th>
                    <th className="table-header-cell">이미지</th>
                    <th className="table-header-cell">우승</th>
                    <th className="table-header-cell">승</th>
                    <th className="table-header-cell">패</th>
                    <th className="table-header-cell">승률</th>
                </tr>
                </thead>
                <tbody>
                {fetchRecordQuery.data?.content?.map((item, index) => (
                    <tr key={index} className="table-row">
                        <td className="table-cell text-center">
                            {currentPage * pageSize + index + 1}
                        </td>
                        <td className="table-cell">{item.name}</td>
                        <td className="table-cell text-center">
                            <img
                                src={item.imageUrl}
                                alt={item.name}
                                className="table-image"
                            />
                        </td>
                        <td className="table-cell text-center">{item.champion}</td>
                        <td className="table-cell text-center">{item.win}</td>
                        <td className="table-cell text-center">{item.lose}</td>
                        <td className="table-cell text-center">
                            {((item.win / (item.win + item.lose)) * 100).toFixed(2)}%
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>

            <div className="pagination-controls">
                <Button
                    variant="outline-secondary"
                    disabled={currentPage === 0}
                    onClick={() => handlePageChange(currentPage - 1)}
                    className="pagination-button me-2"
                >
                    이전
                </Button>

                {[...Array(totalPages)].map((_, index) => (
                    <Button
                        key={index}
                        onClick={() => handlePageChange(index)}
                        active={index === currentPage}
                        variant="outline-secondary"
                        className="pagination-button mx-1"
                    >
                        {index + 1}
                    </Button>
                ))}

                <Button
                    variant="outline-secondary"
                    disabled={currentPage === totalPages - 1}
                    onClick={() => handlePageChange(currentPage + 1)}
                    className="pagination-button ms-2"
                >
                    다음
                </Button>
            </div>
        </div>
    );
};

export default StatisticsTable;
