import SideNavbar from "./SideNavbar";
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "../../Auth/AuthProvider";
import { useEffect, useState } from "react";
import {
    fetchCountUserGetLike,
    fetchCountUserHistory,
    fetchCountUserLike,
    fetchLatestHistory,
    fetchPreference,
    fetchUserInfo
} from "../../api/userAPI";
import UserInfoCard from "./userPageData/UserInfoCard";
import UserScoreCard from "./userPageData/UserScoreCard";
import UserPreferenceCard from "./userPageData/UserPreferenceCard";
import "./css/UserPageData.css";

const useSetVariableWithQueryData = (query, setter) =>
    useEffect(() => {
        if (query.isSuccess && query.data) {
            setter(query.data);
        }
    }, [query.isSuccess, query.data]);

const UserPageData = () => {
    const { token, username } = useAuth();
    const [userInfo, setUserInfo] = useState({});
    const [countUserHistory, setCountUserHistory] = useState({});
    const [countUserLike, setCountUserLike] = useState({});
    const [countUserGetLike, setCountUserGetLike] = useState({});
    const [latestHistory, setLatestHistory] = useState({});
    const [preference, setPreference] = useState({});

    const fetchUserInfoQuery = useQuery({
        queryKey: ["fetchUserInfoQuery", token, username],
        queryFn: fetchUserInfo,
    });

    const fetchCountUserHistoryQuery = useQuery({
        queryKey: ["fetchCountUserHistoryQuery", token, username],
        queryFn: fetchCountUserHistory,
    });

    const fetchCountUserLikeQuery = useQuery({
        queryKey: ["fetchCountUserLikeQuery", token, username],
        queryFn: fetchCountUserLike,
    });

    const fetchCountUserGetLikeQuery = useQuery({
        queryKey: ["fetchCountUserGetLikeQuery", token, username],
        queryFn: fetchCountUserGetLike,
    });

    const fetchLatestHistoryQuery = useQuery({
        queryKey: ["fetchLatestHistoryQuery", token, username],
        queryFn: fetchLatestHistory,
    });

    const fetchPreferenceQuery = useQuery({
        queryKey: ["fetchPreferenceQuery", token, username],
        queryFn: fetchPreference,
    });

    useSetVariableWithQueryData(fetchUserInfoQuery, setUserInfo);
    useSetVariableWithQueryData(fetchCountUserHistoryQuery, setCountUserHistory);
    useSetVariableWithQueryData(fetchCountUserLikeQuery, setCountUserLike);
    useSetVariableWithQueryData(fetchCountUserGetLikeQuery, setCountUserGetLike);
    useSetVariableWithQueryData(fetchLatestHistoryQuery, setLatestHistory);
    useSetVariableWithQueryData(fetchPreferenceQuery, setPreference);

    return (
        <div className="user-page-layout">
            <SideNavbar />
            <div className="content-area">
                <div className="top-section">
                    <div className="score-section">
                        <UserScoreCard
                            userInfo={userInfo}
                            countUserHistory={countUserHistory.count}
                        />
                    </div>
                    <div className="info-section">
                        <UserInfoCard
                            username={username}
                            userInfo={userInfo}
                            countUserHistory={countUserHistory.count}
                            countUserLike={countUserLike.count}
                            countUserGetLike={countUserGetLike.count}
                            latestHistory={latestHistory.date}
                        />
                    </div>
                </div>
                <div className="bottom-section">
                    <UserPreferenceCard preference={preference} />
                </div>
            </div>
        </div>
    );
};

export default UserPageData;
