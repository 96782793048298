import { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/NewTournament.css'
import { useMutation } from "@tanstack/react-query";
import { fetchTournament } from "../../api/tournamentAPI";
import { useAuth } from "../../Auth/AuthProvider";
import useTournamentHub from "./useTournamentHub";

const NewTournament = () => {
    const { token } = useAuth();
    const tournamentHub = useTournamentHub();
    const [numberOfItems, setNumberOfItems] = useState(null);
    const [chatMode, setChatMode] = useState(null);
    const [fetchError, setFetchError] = useState(false);

    // Fetch new tournament if created
    const fetchTournamentMutation = useMutation({
        mutationFn: () => fetchTournament({ token, numberOfItems }),
        onSuccess: async (itemList) => {
            const createdTournament = {
                numberOfItem: itemList.length,
                itemList: itemList,
                survivedItemIndexList: [...Array(itemList.length).keys()],
                nextItem: [0, 1],
                roundInfo: { mainRound: itemList.length, subRound: 1 },
                selectedItemIndexList: [],
                isCreated: true,
            };

            tournamentHub({ tournament: createdTournament, isHost: true, chatMode });
        },
    });

    const handleButtonClick = () => {
        fetchTournamentMutation.mutate();
    };

    return (
        <div className="container my-7" style={{ maxWidth: '600px', margin: '0 auto' }}>
            <div className="card p-3 border-0 shadow-sm" style={{ borderRadius: '15px' }}>
                <h4 className="text-center fw-bold">몇 개의 음식이 경기에 참가할까요?</h4>
                <p className="text-center text-muted">AI가 랜덤한 음식을 추천해드려요</p>

                <div className="d-flex justify-content-between mt-3">
                    {[2, 4, 8, 16].map((value) => (
                        <button
                            key={value}
                            type="button"
                            className={`btn ${numberOfItems === value ? 'btn-success' : 'btn-outline-primary'}`}
                            style={{ flex: '1', margin: '0 5px', borderRadius: '10px' }}
                            onClick={() => setNumberOfItems(value)}
                        >
                            {value}
                        </button>
                    ))}
                </div>
            </div>

            {/* Chat Mode Toggle */}
            <div className="card mt-4 p-3 border-0 shadow-sm" style={{ borderRadius: '15px' }}>
                <h5 className="text-center fw-bold">채팅 모드 선택</h5>
                <div className="d-flex justify-content-between mt-3">
                    {[true, false].map((mode, index) => (
                        <button
                            key={index}
                            type="button"
                            className={`btn ${chatMode === mode ? 'btn-success' : 'btn-outline-primary'}`}
                            style={{ flex: '1', margin: '0 5px', borderRadius: '10px' }}
                            onClick={() => setChatMode(mode)}
                        >
                            {mode === true ? '활성화' : '비활성화'}
                        </button>
                    ))}
                </div>
            </div>

            {/* Start Button */}
            <div className="card mt-4 p-3 border-0 shadow-sm" style={{ borderRadius: '15px' }}>
                <button
                    type="button"
                    className="btn btn-primary w-100"
                    style={{ borderRadius: '10px' }}
                    onClick={handleButtonClick}
                    disabled={numberOfItems === null || chatMode === null}
                >
                    시작하기
                </button>
            </div>

            {/* Loading Spinner */}
            {fetchTournamentMutation.isPending && (
                <div className="text-center mt-3">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div><br/><br/>
                   <span className={'fade-in-text'}>OpenAI가 음식 리스트를 만들고 있습니다. 잠시만 기다려주세요.</span>
                </div>
            )}

            {/* Error Message */}
            {fetchTournamentMutation.isError && (
                <div className="text-center mt-3 text-danger">
                    <p>경기 데이터를 불러오는 중 문제가 발생했습니다. 나중에 다시 시도해주세요.</p>
                </div>
            )}
        </div>
    );
};

export default NewTournament;
