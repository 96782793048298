import {v4} from 'uuid';
import {useNavigate} from 'react-router-dom';

const useTournamentHub = () => {
    const navigate = useNavigate();

    return ({tournament, chatMode, isHost}) => {
        const uniqueRoomId = v4();
        navigate('/tournament', {state: {roomId: uniqueRoomId, tournament, chatMode, isHost}});
    };
};

export default useTournamentHub;