import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Auth/AuthProvider";
import SignUpModal from "./SignUpModal";
import SignUpToast from "./SignUpToast";
import "bootstrap/dist/css/bootstrap.min.css";
import apiBaseUrl from "../../api/config/apiBaseUrl";

const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [showSignupModal, setShowSignupModal] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [toastType, setToastType] = useState("");
    const { saveToken, login } = useAuth();
    const navigate = useNavigate();
    const usernameFocus = useRef();

    const handleLogin = async (e) => {
        e.preventDefault();
        const response = await fetch(`${apiBaseUrl}/login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ username, password }),
        });

        const data = await response.json();

        if (data.status === "success") {
            const token = data.token;
            saveToken(token, username);
            login(username);
            navigate("/");
        } else {
            setToastMessage("아이디 또는 비밀번호가 유효하지 않습니다");
            setToastType("danger");
            setShowToast(true);
        }
    };

    const handleSignupSuccess = (message) => {
        setToastMessage(message);
        setToastType("success");
        setShowToast(true);
        setShowSignupModal(false);
    };

    const handleSignupFailure = (message) => {
        setToastMessage(message);
        setToastType("danger");
        setShowToast(true);
    };

    useEffect(() => {
        usernameFocus.current.focus();
    }, []);

    return (
        <div>
        <div className="container d-flex align-items-center justify-content-center" style={{ height: "90vh" }}>
            <div className="card p-4 shadow-lg" style={{ maxWidth: "400px", width: "100%" }}>
                <h2 className="text-center mb-4">로그인</h2>
                <form onSubmit={handleLogin}>
                    {/* Username Input */}
                    <div className="form-group mb-3">
                        <label htmlFor="username">아이디</label>
                        <input
                            type="text"
                            className="form-control"
                            id="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            placeholder="ID를 입력하세요"
                            ref={usernameFocus}
                            aria-label="아이디 입력"
                        />
                    </div>
                    {/* Password Input */}
                    <div className="form-group mb-3">
                        <label htmlFor="password">비밀번호</label>
                        <input
                            type="password"
                            className="form-control"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="비밀번호를 입력하세요"
                            aria-label="비밀번호 입력"
                        />
                    </div>
                    {/* Buttons */}
                    <div className="d-grid gap-2">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => setShowSignupModal(true)}
                        >
                            회원가입
                        </button>
                        <button type="submit" className="btn btn-primary">
                            로그인
                        </button>
                    </div>
                </form>
            </div>
        </div>
            {/* Signup Modal */}
            {showSignupModal && (
                <SignUpModal
                    onClose={() => setShowSignupModal(false)}
                    onSignupSuccess={handleSignupSuccess}
                    onSignupFailure={handleSignupFailure}
                />
            )}

            {/* Toast Notification */}
            {showToast && (
                <SignUpToast
                    message={toastMessage}
                    type={toastType}
                    onClose={() => setShowToast(false)}
                />
            )}
        </div>
    );

};

export default Login;
