import SideNavbar from "./SideNavbar";
import React from "react";
import StatisticsTable from "../statistics/StatisticsTable";
import {fetchUserRecord} from "../../api/recordAPI";

const UserPageStatistics = () => {
    const pageSize = 8;

    return (
        <div className="d-flex" className="main-content-sidebar">
            <SideNavbar />
            <StatisticsTable pageSize={pageSize} fetchMethod={fetchUserRecord}/>
        </div>
    )
}

export default UserPageStatistics;