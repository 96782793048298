import checkResponse from "./checkResponse/checkResponse";
import apiBaseUrl from "./config/apiBaseUrl";

export const fetchTournament = async ({token, numberOfItems}) => {
    const response = await fetch(`${apiBaseUrl}/tournament`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            numberOfItems: numberOfItems,
        })
    });
    return checkResponse(response, fetchTournament.name);
}

export const sendSelectItemInfo = async ({token, username, itemList, selectedItemIndex, unselectedItemIndex, isChampion}) => {
    const response = await fetch(`${apiBaseUrl}/select`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            'itemList': itemList,
            'selectedItemIndex': selectedItemIndex,
            'unselectedItemIndex': unselectedItemIndex,
            'isChampion': isChampion,
            'username': username,
        })
    });
    return checkResponse(response, sendSelectItemInfo.name);
}

export const saveSuspendedTournament = async ({token, username, itemList, survivedItemIndexList,
                                          nextItem, roundInfo, selectedItemIndexList}) => {
    const response = await fetch(`${apiBaseUrl}/suspended/save`,{
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            'username': username,
            'itemList': itemList,
            'survivedItemIndexList': survivedItemIndexList,
            'nextItem': nextItem,
            'roundInfo': roundInfo,
            'selectedItemsIndex': selectedItemIndexList,
        }),
    });
    return checkResponse(response, saveSuspendedTournament.name);
}

export const getSuspendedTournament = async ({queryKey}) => {
    const [,token, currentPage, pageSize, username] = queryKey;
    const response = await fetch(`${apiBaseUrl}/suspended/user`,{
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            "page": currentPage,
            "size": pageSize,
            "sortBy": "createdAt",
            "sortOrder": "desc",
            "username": username,
        }),
    });
    return checkResponse(response, getSuspendedTournament.name);
}

export const deleteSuspendedTournament = async ({token, tournamentId}) => {
    const response = await fetch(`${apiBaseUrl}/suspended/delete`,{
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            'id': tournamentId,
        }),
    });
    return checkResponse(response, deleteSuspendedTournament.name);
};

export const fetchSingleItem = async (token, roomId, index, name) => {
    const response = await fetch(`${apiBaseUrl}/tournament/update`,{
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            roomId: roomId,
            index: index,
            name: name,
        }),
    });
    return checkResponse(response, fetchSingleItem.name);
};