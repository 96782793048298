import checkResponse from "./checkResponse/checkResponse";
import apiBaseUrl from "./config/apiBaseUrl";

export const fetchAllRecord = async ({queryKey}) => {
    const [, token, currentPage, pageSize, keyword, sortBy, sortOrder] = queryKey;

    const response = await fetch(`${apiBaseUrl}/record`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            page: currentPage,
            size: pageSize,
            sortBy,
            sortOrder,
            keyword,
        })
    });
    return checkResponse(response, fetchAllRecord.name);
}

export const fetchUserRecord = async ({queryKey}) => {
    const [, token, currentPage, pageSize, keyword, sortBy, sortOrder, username] = queryKey;
    const response = await fetch(`${apiBaseUrl}/record/user`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            page: currentPage,
            size: pageSize,
            sortBy,
            sortOrder,
            username,
            keyword,
        })
    });
    return checkResponse(response, fetchAllRecord.name);
}

export const fetchItemRecord = async ({queryKey}) => {
    const [, token, itemName] = queryKey;
    const response = await fetch(`${apiBaseUrl}/record/item`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            itemName,
        }),
    });
    return checkResponse(response, fetchItemRecord.name);
}