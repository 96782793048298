import { NavLink } from "react-router-dom";
import React, {useState} from "react";
import { useAuth } from "../../Auth/AuthProvider";
import RoleDescriptionModal from "./RoleDescriptionModal";
import "./tooltip.css";

const Navbar = () => {
    const { isLoggedIn, logout, username, role } = useAuth();
    const [showRoleDescriptionModal, setShowRoleDescriptionModal] = useState(false);
    const roleTextColor = {'맛잘알': 'pink', '맛초보': 'orange', '맛알못': 'skyblue'};
    const [isHoveredRole, setIsHoveredRole] = useState(false);
    const [isHoveredGitHub, setIsHoveredGitHub] = useState(false);

    const handleMouseEnter = (setter) => setter(true);
    const handleMouseLeave = (setter) => setter(false);

    const roleDescription = {
        '맛잘알': '본인의 선호도가 대다수의 선호도와 매우 유사합니다. 어떠한 음식을 선택해도 다수의 환영을 받습니다.',
        '맛초보': '무난한 취향을 가지고 있습니다.',
        '맛알못': '본인만의 길을 걷고 있습니다. 음식 취향이 높은 확률로 환영받지 못하지만 소수에게는 격렬한 환영을 받을 수도 있습니다.',
    };


    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top shadow-sm">
            <div className="container">
                {/* Left Section: Navigation Links */}
                <NavLink className="navbar-brand fw-bold" to="/">
                    Food Tournament
                </NavLink>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav me-auto">
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/">
                                메인
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/statistics">
                                통계
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/dev">
                                개발 기록
                            </NavLink>
                        </li>
                        <li className="nav-item"
                            style={{ display: 'inline-block', position: 'relative', cursor: 'pointer' }}
                            onMouseEnter={()=>handleMouseEnter(setIsHoveredGitHub)}
                            onMouseLeave={()=>handleMouseLeave(setIsHoveredGitHub)}
                        >
                            <a
                                className="nav-link d-flex align-items-center"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="currentColor"
                                    className="bi bi-github me-1"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82a7.52 7.52 0 0 1 4.01 0c1.53-1.03 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.01 8.01 0 0 0 16 8c0-4.42-3.58-8-8-8z" />
                                </svg>
                                GitHub
                            </a>
                            {isHoveredGitHub && (
                                <div className="button-container github-link">
                                    <button>
                                        <a
                                            href="https://github.com/zhdtladl/food_tournament_app_public"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            서버 소스 코드
                                        </a>
                                    </button>
                                    <button>
                                        <a
                                            href="https://github.com/zhdtladl/food_tournament_app_front_public"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            프론트 소스 코드
                                        </a>
                                    </button>
                                </div>

                            )}
                        </li>
                    </ul>

                    {/* Right Section: User Controls */}
                    <div className="d-flex align-items-center">
                        {isLoggedIn ? (
                            <>
                                <div
                                    className="role-container"
                                    onMouseEnter={()=>handleMouseEnter(setIsHoveredRole)}
                                    onMouseLeave={()=>handleMouseLeave(setIsHoveredRole)}
                                    style={{ display: 'inline-block', position: 'relative', cursor: 'pointer' }}
                                >
                                    <span
                                        className="navbar-text me-3 fw-semibold"
                                        style={{ color: roleTextColor[role], }}
                                    >
                                        {role}
                                    </span>
                                    {isHoveredRole && (
                                        <div className="tooltip-box">
                                            <strong>{role}</strong>
                                            <p>{roleDescription[role]}</p>
                                            <button
                                                className="btn btn-outline-primary btn-sm"
                                                onClick={()=>setShowRoleDescriptionModal(true)}
                                            >자세히</button>
                                        </div>
                                    )}
                                </div>
                                <span className="navbar-text me-3 fw-semibold">{username}</span>
                                <NavLink className="nav-link" to={`/user/${username}/data`}>
                                    마이페이지
                                </NavLink>
                                <button
                                    onClick={logout}
                                    className="btn btn-outline-secondary ms-2"
                                >
                                    로그아웃
                                </button>
                            </>
                        ) : (
                            <NavLink className="btn btn-outline-primary" to="/login">
                                로그인
                            </NavLink>
                        )}
                    </div>
                </div>
            </div>

            {/* Signup Modal */}
            {showRoleDescriptionModal && (
                <RoleDescriptionModal
                    showModal={showRoleDescriptionModal}
                    onClose={()=>setShowRoleDescriptionModal(false)}
                    roleTextColor={roleTextColor}
                    style={{ position: 'absolute', top: '100%', left: '0', zIndex: 1050 }}
                    roleDescription={roleDescription}
                />
            )}
        </nav>
    );
};

export default Navbar;
