import {useMutation, useQueryClient} from "@tanstack/react-query";
import {deleteHistory} from "./historyAPI";
import {deleteSuspendedTournament} from "./tournamentAPI";
import {toggleLike} from "./likeAPI";

export const useDeleteHistoryMutation = (token, username) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({tournament}) => deleteHistory({
                token,
                historyId: tournament.id,
                username,
                isCreated: tournament.isCreated,
            }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['fetchUserHistory'] });
        },
        onError: (error) => {
            console.log('useDeleteHistoryMutation', error);
        }
    });
}

export const useDeleteSuspendedTournamentMutation = (token) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({tournament}) => deleteSuspendedTournament({
                token,
                tournamentId: tournament.id,
            }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['fetchSuspendedTournament'] });
        },
        onError: (error) => {
            console.log('useDeleteSuspendedTournamentMutation', error);
        }
    });
}

export const useUnlikeHistoryMutation = (token, username) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({tournament}) => toggleLike(token, username, tournament.id),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['fetchUserLikedHistory'] });
        },
        onError: (error) => {
            console.log('useUnlikeHistoryMutation', error);
        }
    });
}