import {Route, Routes} from "react-router-dom";
import './App.css'
import HomePage from "./foodTournament/home/HomePage";
import Login from "./foodTournament/login/Login";
import NewTournament from "./foodTournament/tournament/NewTournament";
import {AuthProvider} from "./Auth/AuthProvider";
import Navbar from "./foodTournament/home/Navbar";
import ProtectedRoute from "./Auth/ProtectedRoute";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import ClearSessionStorage from "./router/ClearSessionStorage";
import {useState} from "react";
import TokenExpirationToast from "./Auth/TokenExpirationToast";
import Statistics from "./foodTournament/statistics/Statistics";
import UserPageHistory from "./foodTournament/userPage/UserPageHistory";
import UserPageStatistics from "./foodTournament/userPage/UserPageStatistics";
import DevHistory from "./dev_history/DevHistory";
import TournamentUI from "./foodTournament/tournament/TournamentUI";
import UserPageData from "./foodTournament/userPage/UserPageData";

const App = () => {
    const [showToast, setShowToast] = useState(false);
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <AuthProvider>
                <ClearSessionStorage />
                <Navbar/>
                <div className="main-content">
                    <Routes>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/login" element={<Login />}/>
                        <Route path="/statistics" element={<Statistics />}/>
                        <Route path="/dev" element={<DevHistory />}/>


                        <Route path="/start" element={
                            <ProtectedRoute element={<NewTournament />} />} />
                        <Route path="/tournament"
                               element={<ProtectedRoute element={<TournamentUI />} />} />
                        <Route path="/user/:username/data"
                               element={<ProtectedRoute element={<UserPageData />} />} />
                        <Route path="/user/:username/history"
                               element={<ProtectedRoute element={<UserPageHistory />} />} />
                        <Route path="/user/:username/statistics"
                               element={<ProtectedRoute element={<UserPageStatistics/>}/>} />
                    </Routes>
                </div>
                <TokenExpirationToast showToast={showToast} setShowToast={setShowToast}/>
            </AuthProvider>
        </QueryClientProvider>
    );
}

export default App;
