import React from "react";
import "../css/UserInfoCard.css";

const UserInfoCard = ({ username, userInfo, countUserHistory, countUserLike, countUserGetLike, latestHistory }) => {
    const roleNameColor = { '맛잘알': 'pink', '맛초보': 'orange', '맛알못': 'skyblue' };
    const roleDescription = {
        '맛잘알': '본인의 선호도가 대다수의 선호도와 매우 유사합니다. \n어떠한 음식을 선택해도 다수의 환영을 받습니다.',
        '맛초보': '무난한 취향을 가지고 있습니다.',
        '맛알못': '본인만의 길을 걷고 있습니다. \n음식 취향이 높은 확률로 환영받지 못하지만 \n소수에게는 격렬한 환영을 받을 수도 있습니다.',
    };

    return (
        <div className="user-info-card">
            <div className="user-header">
                <h2 className="user-name">{username}</h2>
                <span
                    className="user-role"
                    style={{ backgroundColor: roleNameColor[userInfo.role] }}
                >
                    {userInfo.role}
                </span>
            </div>
            <p className="role-description">{roleDescription[userInfo.role]}</p>
            <div className="user-info-details">
                <div className="info-row">
                    <span className="info-label">가입일</span>
                    <span className="info-value">
                        {new Date(userInfo.createdAt).toLocaleDateString()}{" "}
                        {new Date(userInfo.createdAt).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                        })}
                    </span>
                </div>
                <div className="info-row">
                    <span className="info-label">경기 수</span>
                    <span className="info-value">{countUserHistory}</span>
                </div>
                <div className="info-row">
                    <span className="info-label">좋아요 누른 경기 수</span>
                    <span className="info-value">{countUserLike}</span>
                </div>
                <div className="info-row">
                    <span className="info-label">좋아요 받은 경기 수</span>
                    <span className="info-value">{countUserGetLike}</span>
                </div>
                <div className="info-row">
                    <span className="info-label">최근 경기</span>
                    <span className="info-value">
                        {latestHistory
                            ? `${new Date(latestHistory).toLocaleDateString()} ${new Date(
                                latestHistory
                            ).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}`
                            : "-"}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default UserInfoCard;
