import checkResponse from "./checkResponse/checkResponse";
import {useMutation} from "@tanstack/react-query";
import apiBaseUrl from "./config/apiBaseUrl";

export const fetchChatHistory = async ({roomId}) => {
    const response = await fetch(`${apiBaseUrl}/chat/history/${roomId}`,{
        method: 'GET',
    });
    return checkResponse(response, fetchChatHistory.name);
}

export const terminateChat = async ({roomId}) => {
    const response = await fetch(`${apiBaseUrl}/chat/terminate/${roomId}`,{
        method: 'DELETE',
    });
    return checkResponse(response, terminateChat.name);
}

export const fetchAllChatRoomInfo = async ({queryKey}) => {
    const response = await fetch(`${apiBaseUrl}/chat/chat-room`,{
        method: 'GET',
    });
    return checkResponse(response, fetchAllChatRoomInfo.name);
}

export const fetchChatRoomInfoById = async ({queryKey}) => {
    const [, roomId ] = queryKey;
    const response = await fetch(`${apiBaseUrl}/chat/chat-room/${roomId}`,{
        method: 'GET',
    });
    return checkResponse(response, fetchChatRoomInfoById.name);
}

export const updateChatRoomInfo = async ({roomId, username, add}) => {
    const response = await fetch(`${apiBaseUrl}/chat/chat-room/update`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            roomId: roomId,
            username: username,
            add: add,
        }),
    });
    return checkResponse(response, updateChatRoomInfo.name);
}

export const useFetchHistoryMutation = (roomId) => {
    return useMutation({
        mutationFn: () => fetchChatHistory({ roomId }),
        onError: error => {
            console.log('fetchChatHistoryMutation', error);
        }
    });
}

export const useFetchChatRoomInfoByIdMutation = (roomId, setUsers) => {
    return useMutation({
        mutationFn: () => fetchChatRoomInfoById({queryKey:['',roomId]}),
        onSuccess: (data) => {
            setUsers(data.joinedUserList);
        },
        onError: error => {
            console.log('fetchChatRoomInfoByIdMutation', error);
        }
    })
}