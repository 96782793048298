import {createContext, useContext, useEffect, useState} from 'react';
import {useQuery} from "@tanstack/react-query";
import {fetchUserInfo} from "../api/userAPI";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(() => localStorage.getItem('token'));
    const [isLoggedIn, setIsLoggedIn] = useState(!!token);
    const [username, setUsername] = useState(() => localStorage.getItem('username') || '');
    const [role, setRole] = useState('');

    const fetchUserInfoQuery = useQuery({
        queryKey: ['fetchUserInfoQuery', token, username],
        queryFn: fetchUserInfo,
        enabled: !!username,
    })

    useEffect(()=>{
        if (fetchUserInfoQuery.isSuccess && fetchUserInfoQuery.data) {
            setRole(fetchUserInfoQuery.data.role);
        }
    },[fetchUserInfoQuery.isSuccess, fetchUserInfoQuery.data])

    const saveToken = (newToken, newUsername) => {
        localStorage.setItem('token', newToken);
        localStorage.setItem('username', newUsername);
        setToken(newToken);
        setUsername(newUsername);
        setIsLoggedIn(true);
    };

    const clearToken = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        setToken(null);
        setIsLoggedIn(false);
        setUsername('');
    };

    const login = (username) => {
        setIsLoggedIn(true);
        setUsername(username);
    };

    const logout = () => {
        clearToken();
    };

    useEffect(() => {
        if (token) {
            setIsLoggedIn(true);
            setUsername(username);
        } else {
            setIsLoggedIn(false);
            setUsername('');
        }
    },[token])

    return (
        <AuthContext.Provider value={{ token, saveToken, clearToken, isLoggedIn, username, login, logout, role }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);