import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {fetchAllChatRoomInfo, fetchChatHistory, fetchChatRoomInfoById} from "../../api/chatAPI";
import PostCard from "../post/PostCard";
import UnauthenticatedToast from "../login/UnauthenticatedToast";
import { useState, useEffect } from "react";
import ChatRoomDetail from "./ChatRoomDetail";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../Auth/AuthProvider";

const LiveTournament = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { username } = useAuth();

    const [chatRoomInfoList, setChatRoomInfoList] = useState(null);
    const [chatHistory, setChatHistory] = useState(null);
    const [currentRoomId, setCurrentRoomId] = useState('');

    const [showDetail, setShowDetail] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');

    const fetchChatRoomInfoQuery = useQuery({
        queryKey: ['fetchChatRoomInfo'],
        queryFn: fetchAllChatRoomInfo,
    });

    const handleTermination = () => {
        queryClient.invalidateQueries({ queryKey: ['fetchChatRoomInfo'] });
        setToastMessage('종료된 경기입니다.');
        setShowToast(true);
    }

    const fetchChatRoomInfoByIdMutation = useMutation({
        mutationFn: (roomId) => fetchChatRoomInfoById({queryKey:['',roomId]}),
        onSuccess: (data) => {
            if (!data.roomId) {
                handleTermination();
            } else {
                navigate('/tournament', { state: {roomId: currentRoomId, tournament: {}, isHost: false, chatMode: data.chatMode} });
            }
        }
    })

    const fetchTournamentStateMutation = useMutation({
        mutationFn: (roomId) => fetchChatHistory({ roomId }),
        onSuccess: (data) => {
            if (!data.tournamentState.itemList.length) {
                handleTermination();
            } else {
                setChatHistory(data);
                setShowDetail(true);
            }
        },
    });

    useEffect(() => {
        if (fetchChatRoomInfoQuery.isSuccess && fetchChatRoomInfoQuery.data) {
            setChatRoomInfoList(fetchChatRoomInfoQuery.data);
        }
    }, [fetchChatRoomInfoQuery.isSuccess, fetchChatRoomInfoQuery.data]);

    const handleShowDetail = (roomId) => {
        setCurrentRoomId(roomId);
        fetchTournamentStateMutation.mutate(roomId);
    };

    const handleEnterClick = (roomId) => {
        setCurrentRoomId(roomId);
        fetchChatRoomInfoByIdMutation.mutate(roomId);
    };

    return (
        <div className="container py-5">
            {!!chatRoomInfoList?.length ? (
                <div className="row row-cols-1 row-cols-md-3 g-4">
                    {chatRoomInfoList.map((chatRoomInfo, index) => (
                    <div key={index} className="col">
                        <div className="card h-100 border-0 shadow-sm">
                            <div className="d-flex justify-content-center">
                                <PostCard itemList={chatRoomInfo.itemList} />
                            </div>
                            <div className="card-body"  >
                                <div className="d-flex justify-content-between align-items-center">
                                    <h5 className="card-title text-truncate" title={chatRoomInfo.hostName}>{chatRoomInfo.hostName}</h5>
                                    <h6>{`${chatRoomInfo.itemList.length}개 참가`}</h6>
                                    <small className="text-muted">
                                        <small className="text-muted">
                                            <strong>시작시간</strong> {chatRoomInfo.createdAt}
                                        </small>
                                        <small className="text-muted">
                                            <br/><strong>채팅</strong>{chatRoomInfo.chatMode? " 허용":" 비허용"}
                                        </small>
                                    </small>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <button
                                        className="btn btn-outline-danger btn-sm"
                                        onClick={() => handleEnterClick(chatRoomInfo.roomId)}
                                    >
                                        참가하기
                                    </button>
                                    <button
                                        className="btn btn-outline-primary btn-sm"
                                        onClick={() => handleShowDetail(chatRoomInfo.roomId)}
                                    >
                                        자세히
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                </div>
                ): (<div className="text-center">진행 중인 경기가 없습니다</div>)}


            {showDetail && (
                <ChatRoomDetail
                    roomId={currentRoomId}
                    showDetail={showDetail}
                    chatHistory={chatHistory}
                    onClose={() => setShowDetail(false)}
                    handleEnterClick={handleEnterClick}
                />
            )}

            {showToast && (
                <UnauthenticatedToast
                    message={toastMessage}
                    type={'warning'}
                    onClose={() => setShowToast(false)}
                />
            )}
        </div>
    );
};

export default LiveTournament;
