import React from "react";
import "../css/ItemCard.css"

const ItemCard = ({tournament, index, isSelected}) => {
    const image = index >= 0? tournament.itemList[index].url:'https://www.citypng.com/public/uploads/preview/outline-white-question-mark-symbol-icon-png-701751694963538mizgiavh3e.png';
    const name = index >= 0? tournament.itemList[index].name:'　'
    return (
        <div
            className="thumbnail-container"
            style={{
                textAlign: 'center',
                margin: '5px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
            }}
        >
            <img
                src={image}
                alt={name}
                className="thumbnail-image"
                style={{width: '3em', height: '3em', border: isSelected ? '3px #846bff solid':''}}
            />
            <p style={{ margin: '5px 0', fontSize: '13px' }}>
                {name}
            </p>
        </div>
    );
}

export default ItemCard;