import apiBaseUrl from "./config/apiBaseUrl";

export const toggleLike = async (token, username, tournamentId) => {
    await fetch(`${apiBaseUrl}/like`,{
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            'username': username,
            'tournamentId': tournamentId,
        })
    })
}