import React, { useEffect } from 'react';

const SignUpToast = ({ message, type, onClose }) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            onClose();
        }, 5000);

        return () => clearTimeout(timer);
    }, [onClose]);

    return (
        <div className="toast show position-fixed top-0 end-0 m-3" style={{ zIndex: 1055 }}>
            <div className={`toast-header bg-${type} text-white`}>
                <strong className="me-auto">Notification</strong>
                <button type="button" className="btn-close" onClick={onClose}></button>
            </div>
            <div className="toast-body">
                {message}
            </div>
        </div>
    );
};

export default SignUpToast;
