import {Modal} from "react-bootstrap";
import TournamentDiagram from "../post/postDatail/TournamentDiagram";

const ChatRoomDetail = ({ roomId, showDetail, chatHistory, onClose, handleEnterClick }) => {
    return (
        <Modal show={showDetail} onHide={onClose} centered>
            <Modal.Header>
                <Modal.Title>경기 상황</Modal.Title>
                <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    onClick={onClose}
                    style={{
                        background: 'transparent',
                        border: 'none',
                        fontSize: '1.5rem',
                        lineHeight: '1',
                        color: '#000',
                    }}
                >
                    &times;
                </button>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: '500px', overflowY: 'auto' }}>
                <TournamentDiagram tournament={{
                        itemList: chatHistory.tournamentState.itemList,
                        selectedItemsIndex: chatHistory.tournamentState.selectedItemsIndex,
                    }} />
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => handleEnterClick(roomId)}
                >
                    참가하기
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default ChatRoomDetail;