import checkResponse from "./checkResponse/checkResponse";
import apiBaseUrl from "./config/apiBaseUrl";

export const fetchUserInfo = async ({queryKey}) => {
    const [,token, username] = queryKey;
    const response = await fetch(`${apiBaseUrl}/user/${encodeURIComponent(username)}`,{
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    });
    return checkResponse(response, fetchUserInfo.name);
}

export const fetchCountUserHistory = async ({queryKey}) => {
    const [,token, username] = queryKey;
    const response = await fetch(`${apiBaseUrl}/history/user/count/${encodeURIComponent(username)}`,{
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    });
    return checkResponse(response, fetchCountUserHistory.name);
}

export const fetchCountUserLike = async ({queryKey}) => {
    const [,token, username] = queryKey;
    const response = await fetch(`${apiBaseUrl}/like/user/count/${encodeURIComponent(username)}`,{
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    });
    return checkResponse(response, fetchCountUserLike.name);
}

export const fetchCountUserGetLike = async ({queryKey}) => {
    const [,token, username] = queryKey;
    const response = await fetch(`${apiBaseUrl}/like/user/count/get/${encodeURIComponent(username)}`,{
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    });
    return checkResponse(response, fetchCountUserGetLike.name);
}

export const fetchLatestHistory = async ({queryKey}) => {
    const [, token, username] = queryKey;
    const response = await fetch(`${apiBaseUrl}/history/user/latest/${encodeURIComponent(username)}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    });
    return checkResponse(response, fetchLatestHistory.name);
}

export const updateUserInfo = async (token, username, role, score) => {
    const response = await fetch(`${apiBaseUrl}/user/update}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            username: username,
            role: role,
            addToScore: score,
        }),
    });
    return checkResponse(response, updateUserInfo.name);
}

export const fetchPreference = async ({queryKey}) => {
    const [, token, username] = queryKey;
    const response = await fetch(`${apiBaseUrl}/record/user-page/${encodeURIComponent(username)}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    });
    return checkResponse(response, fetchPreference.name);
}