import React, { useState, useEffect } from 'react';
import '../../css/Chat.css';
import { useAuth } from "../../Auth/AuthProvider";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchChatRoomInfoById } from "../../api/chatAPI";
import {NavLink} from "react-router-dom";

const ChatMessage = ({ roomId, chatMessageList, stompClient, isHost, chatMode }) => {
    const { username } = useAuth();
    const [messages, setMessages] = useState(null);
    const [inputMessage, setInputMessage] = useState('');
    const [users, setUsers] = useState([]);
    const queryClient = useQueryClient();
    const [isFocused, setIsFocused] = useState(false);

    const fetchChatRoomInfoByIdQuery = useQuery({
        queryKey: ['fetchChatRoomInfoByIdQuery', roomId],
        queryFn: fetchChatRoomInfoById,
        enabled: !!roomId,
    });

    useEffect(() => {
        if (fetchChatRoomInfoByIdQuery.isSuccess && fetchChatRoomInfoByIdQuery.data) {
            setUsers(fetchChatRoomInfoByIdQuery.data.joinedUserList);
        }
    }, [fetchChatRoomInfoByIdQuery.isSuccess, fetchChatRoomInfoByIdQuery.data]);

    useEffect(() => {
        !!chatMessageList && setMessages([...chatMessageList]);
    }, [chatMessageList, roomId, stompClient]);

    useEffect(() => {
        if (!stompClient || !stompClient.connected) {
            return;
        }

        const subscription = stompClient.subscribe(`/topic/message/${roomId}`, (message) => {
            const parsedMessage = JSON.parse(message.body);
            setMessages((prevMessages) => [...prevMessages, parsedMessage]);
            if (parsedMessage.sender === 'host') {
                queryClient.invalidateQueries({queryKey: ['fetchChatRoomInfoByIdQuery']});
            }
        });

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [roomId, stompClient]);

    const sendMessage = () => {
        if (inputMessage.trim() && stompClient) {
            const chatMessage = {
                sender: username,
                content: inputMessage,
                timestamp: new Date().toLocaleTimeString(),
            };

            stompClient.publish({
                destination: `/app/chat/${roomId}/message`,
                body: JSON.stringify(chatMessage),
            });

            setInputMessage('');
        }
    };

    return (
        <div className="d-flex flex-column chat-info">
            <div className="user-list border-bottom pb-3 mb-3" style={{ flexShrink: 0 }}>
                <div className="d-flex justify-content-between align-items-center">
                    <h5 className="fw-bold">현재 {users.length}명 참여 중</h5>
                    <NavLink className="btn btn-outline-primary" to="/">나가기</NavLink>
                </div>

                <div className="user-list-items d-flex flex-wrap align-items-center mt-2">
                    {users?.map((user, index) => (
                        <div key={index} className="text-muted">{user}</div>
                    ))}
                </div>
            </div>

            <div className="chat-container flex-grow-1 d-flex flex-column overflow-hidden h-100 w-100" style={{ maxWidth: '100%' }}>
                <h6 className="text-primary fw-bold mb-3">채팅</h6>
                <div className="messages-container flex-grow-1 overflow-auto d-flex flex-column h-100 w-100" style={{ maxWidth: '100%' }} ref={(el) => { if (el) el.scrollTop = el.scrollHeight; }} >
                    {messages?.map((msg, index) => (
                        msg.sender === 'host' ? (
                            <div
                                key={index}
                                className="mb-1 text-muted"
                                style={{ fontSize: '0.9rem', textAlign: 'center' }}
                            >
                                {msg.content}
                            </div>
                        ) : (
                            <div
                                key={index}
                                className={`mb-2 p-2 rounded ${msg.sender === username ? 'bg-primary text-white' : 'bg-light text-dark'}`}
                                style={{ maxWidth: '80%', alignSelf: msg.sender === username ? 'flex-end' : 'flex-start' }}
                            >
                                <strong>{msg.sender}:</strong> {msg.content}
                            </div>
                        )
                    ))}
                </div>
                {chatMode ? (
                    <div className="input-container mt-3 d-flex">
                        <input
                            type="text"
                            className="form-control me-2"
                            placeholder={isFocused ? '' : '채팅을 입력하세요.'}
                            value={inputMessage}
                            onChange={(e) => setInputMessage(e.target.value)}
                            onFocus={() => setIsFocused(true)}
                            onBlur={() => setIsFocused(false)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') sendMessage();
                            }}
                        />
                        <button className="btn btn-primary" onClick={sendMessage}>Send</button>
                    </div>
                ) : (
                    <div className="text-danger mt-3">채팅이 금지되었습니다.</div>
                )}
            </div>
        </div>
    );
};

export default ChatMessage;
