import React, { useCallback, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { fetchAllHistory } from "../../api/historyAPI";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useAuth } from "../../Auth/AuthProvider";
import PostContainer from "../post/PostContainer";
import LiveTournament from "./LiveTournament";

const HomePage = () => {
    const { username } = useAuth();
    const [historyList, setHistoryList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [hasNextPage, setHasNextPage] = useState(true);
    const [isFetchingNextPage, setIsFetchingNextPage] = useState(false);
    const pageSize = 6;

    const fetchAllHistoryQuery = useQuery({
        queryKey: ["fetchAllHistoryQuery", currentPage, pageSize, username],
        queryFn: fetchAllHistory,
        placeholderData: keepPreviousData,
        gcTime: 0,
        enabled: hasNextPage,
    });

    useEffect(() => {
        if (fetchAllHistoryQuery.isSuccess && fetchAllHistoryQuery.data) {
            fetchAllHistoryQuery.data.last && setHasNextPage(false);
            setHistoryList((prevList) => [
                ...prevList,
                ...fetchAllHistoryQuery.data.content,
            ]);
            setIsFetchingNextPage(false);
        }
    }, [fetchAllHistoryQuery.isSuccess, fetchAllHistoryQuery.data]);

    const handleScroll = useCallback(() => {
        const scrollPosition = window.innerHeight + window.scrollY;
        const threshold = document.body.offsetHeight - 200;

        if (scrollPosition >= threshold && !isFetchingNextPage) {
            setIsFetchingNextPage(true);
            setCurrentPage((prevPage) => prevPage + 1);
        }
    }, [isFetchingNextPage]);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [handleScroll]);

    return (
        <div>
            {/* Hero Section */}
            <header className="bg-light text-dark text-center py-5 mb-4">
                <div className="container">
                    <h1 className="display-5">Food Tournament</h1>
                    <p className="lead">
                        오늘의 메뉴를 정해보세요!
                    </p>
                    <NavLink className="btn btn-primary btn-lg mt-3" to="/start">
                        시작하기
                    </NavLink>
                </div>
            </header>
            {/* Live tournament */}
            <main className="container mb-5">
                <h2 className="text-center mb-4">실시간 경기</h2>
                <div className="row justify-content-center">
                    <LiveTournament />
                </div>
            </main>

            {/* Recent Matches Section */}
            <main className="container mb-5">
                <h2 className="text-center mb-4">최근 경기</h2>
                <div className="row justify-content-center">
                    <PostContainer historyList={historyList} setHistoryList={setHistoryList} />
                </div>
                {hasNextPage && (
                    <div className="text-center mt-3">
                        {isFetchingNextPage ? (
                            <div className="spinner-border text-primary" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        ) : (
                            <button
                                className="btn btn-outline-primary"
                                onClick={() => setCurrentPage((prev) => prev + 1)}
                                disabled={isFetchingNextPage}
                            >
                                Load More
                            </button>
                        )}
                    </div>
                )}
            </main>

            {/* Footer Section */}
            <footer className="bg-dark text-white py-3">
                <div className="container text-center">
                    <p className="mb-0">
                        &copy; 2024 Food Tournament. Some rights reserved.
                    </p>
                </div>
            </footer>
        </div>
    );
};

export default HomePage;
