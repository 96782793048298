import React from "react";

const PostCard = ({itemList}) => {
    const createThumbnail = (itemList) => {
        const transparentImage = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/wcAAwAB/khO5dkAAAAASUVORK5CYII=';
        if (itemList.length <= 2) {
            itemList = [...itemList,null,null];
        }
        return itemList.slice(0,4).map((item, index) => (
            <img
                key={index}
                src={item? item.url:transparentImage}
                alt=""
                className="thumbnail-image"
            />
        ))};

    return (
        <div className="thumbnail-grid">
            {createThumbnail(itemList)}
        </div>
    );
};

export default PostCard;