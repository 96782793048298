import React, { useState } from 'react';
import { useAuth } from "../../Auth/AuthProvider";
import { useQuery } from "@tanstack/react-query";
import { getSuspendedTournament } from "../../api/tournamentAPI";
import { fetchUserHistory, fetchUserLikedHistory } from "../../api/historyAPI";
import CardContainer from "./CardContainer";
import SideNavbar from "./SideNavbar";

const UserPageHistory = () => {
    const { token, username } = useAuth();
    const [currentPage, setCurrentPage] = useState(0);
    const pageSize = 3;

    const fetchSuspendedTournamentQuery = useQuery({
        queryKey: ['fetchSuspendedTournament', token, currentPage, pageSize, username],
        queryFn: getSuspendedTournament,
        enabled: !!token,
    });

    const fetchUserHistoryQuery = useQuery({
        queryKey: ['fetchUserHistory', token, currentPage, pageSize, username],
        queryFn: fetchUserHistory,
        enabled: !!token,
    });

    const fetchUserLikedHistoryQuery = useQuery({
        queryKey: ['fetchUserLikedHistory', token, currentPage, pageSize, username],
        queryFn: fetchUserLikedHistory,
        enabled: !!token,
    });

    const handlePageChange = (page) => {
        if (page >= 0 && page < fetchSuspendedTournamentQuery.data?.totalPages) {
            setCurrentPage(page);
        }
    };

    if (fetchSuspendedTournamentQuery.isLoading || fetchUserHistoryQuery.isLoading || fetchUserLikedHistoryQuery.isLoading) {
        return <div>Loading...</div>;
    }

    if (fetchSuspendedTournamentQuery.isError || fetchUserHistoryQuery.isError || fetchUserLikedHistoryQuery.isError) {
        return <div>Error loading data</div>;
    }

    return (
        <div className="d-flex">
            <SideNavbar />
            <div className="flex-grow-1 p-4" style={{ marginLeft: '200px', minHeight: '100vh' }}>
                <CardContainer
                    containerName={"이어서 하기"}
                    list={fetchSuspendedTournamentQuery.data?.content || []}
                    type={'suspended'}
                    refetch={fetchSuspendedTournamentQuery.refetch}
                    style={{ marginBottom: '2rem' }} // Spacing between sections
                />
                <CardContainer
                    containerName={"지난 경기"}
                    list={fetchUserHistoryQuery.data?.content || []}
                    type={'history'}
                    refetch={fetchUserHistoryQuery.refetch}
                    style={{ marginBottom: '2rem' }} // Spacing between sections
                />
                <CardContainer
                    containerName={"좋아요 누른 경기"}
                    list={fetchUserLikedHistoryQuery.data?.content || []}
                    type={'like'}
                    refetch={fetchUserLikedHistoryQuery.refetch}
                    style={{ marginBottom: '2rem' }} // Spacing between sections
                />
            </div>
        </div>
    );
};

export default UserPageHistory;
